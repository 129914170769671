<template>
    <!-- 可选 -->
    <div class="tree-wrapper box">
        <!-- title -->
        <!-- <select-title title="选择"></select-title> -->
        <!-- 搜索 -->
        <select-input
            :dropDownSearchType="dropDownSearchType"
            @selectFocus="selectFocus"
            @selectInput="selectInput"
            :placeholder="placeholder"
        >
            <select-option
                :searchDropdownData="searchDropdownData"
                :searchOptionalLoadTitle="searchOptionalLoadTitle"
                @handlerSelectTreeNodeClick="handlerSelectTreeNodeClick"
            ></select-option>
        </select-input>
        <div class="meetingLaunchAddTab">
            <span @click="changeLaunchType(1)" :class="{commonSpan:true,activeType:currentType==1}">按人员选</span>
            <span @click="changeLaunchType(2)" :class="{commonSpan:true,activeType:currentType==2}">按架构选</span>
        </div>
        <div v-show="currentType==1&&meetingSelectPeopleList.length>0" style="height: 353px; overflow-y: auto;">
            <el-checkbox-group v-model="checkCitys" @change="meetingPeopleListChange">
                <el-checkbox  v-for="item in meetingSelectPeopleList"  :label="item.id" :key="item.id" :disabled="item.disabled">
                    <div class="checkbox-people">
                        <div class="checkbox-people-icon">
                            <img v-if="item.avatar" :src="item.avatar" alt="" title="">
                            <div v-else class="checkbox-people-icon-text">
                                <span v-if="item.teacherName">{{ item.teacherName.substring(0,1) }}</span>
                            </div>
                        </div>
                        <div class="checkbox-people-name">
                            <span>{{ item.teacherName }}</span>
                        </div>
                    </div>
                </el-checkbox>
            </el-checkbox-group>
        </div>
        <div v-show="currentType==2">
            <!-- 面包屑 -->
            <Breadcrumbs
                :breadcrumbsList="breadcrumbsList"
                @changeTreeListLevel="changeTreeListLevel"
            />
            <!-- 选择数据 -->
            <div
                class="tree-option"
                v-if="treeShowList && treeShowList.length > 0"
            >
                <div v-if="checkUserOnly">
                    <div class="all-check" v-if="treeShowList[0].type !== 'organ'">
                        <input
                            class="input"
                            type="checkbox"
                            id="all"
                            :checked="allCheck"
                            @click="handlerAllTreeNodeClick($event)"
                        />
                        <label
                            for="all"
                            class="all-check-label"
                        >全选</label
                        >
                    </div>
                </div>
                <div v-else>
                    <div class="all-check" v-if="multiple && !hasOrgan">
                        <input
                            class="input"
                            type="checkbox"
                            id="all"
                            :checked="allCheck"
                            @click="handlerAllTreeNodeClick($event)"
                        />
                        <label
                            for="all"
                            class="all-check-label"
                        >全选</label
                        >
                    </div>
                </div>
                <!-- 列表 -->
                <div class="tree-list">
                    <div
                        v-for="(item, index) of treeShowList"
                        :key="index"
                    >
                        <div class="tree-item">
                            <div class="input-item">
                                <div v-if="checkUserOnly">
                                    <input
                                        v-if="item.type !== 'organ'"
                                        class="input"
                                        :class="{disabled:item.disabled}"
                                        type="checkbox"
                                        :id="item.id"
                                        :checked="true"
                                        :disabled="item.disabled||false"
                                        @click="handlerTreeNodeClick($event, item)"
                                    />
                                </div>
                                <div v-else-if="checkAll">
                                    <input
                                        class="input"
                                        :class="{disabled:item.disabled}"
                                        type="checkbox"
                                        :id="item.id"
                                        :checked="item.checked&&item.type != 'organ'"
                                        :disabled="item.disabled||false"
                                        @click="handlerTreeNodeClick($event, item)"
                                    />
                                </div>
                                <div v-else>
                                    <input
                                        v-if="multiple&&item.type == 'organ'"
                                        class="input inputOrgan"
                                        type="checkbox"
                                        :id="item.id"
                                        :checked="item.checked&&item.type != 'organ'"
                                        :disabled="true"
                                        @click="handlerTreeNodeClick($event, item)"
                                    />
                                    <input
                                        v-else-if="multiple"
                                        class="input"
                                        :class="{disabled:item.disabled}"
                                        type="checkbox"
                                        :id="item.id"
                                        :checked="item.checked"
                                        :disabled="item.disabled||false"
                                        @click="handlerTreeNodeClick($event, item)"
                                    />
                                    <input
                                        v-else-if="!multiple && item.type !== 'organ'"
                                        class="input"
                                        :class="{disabled:item.disabled}"
                                        type="checkbox"
                                        :disabled="item.disabled||false"
                                        :id="item.id"
                                        :checked="item.checked"
                                        @click="handlerTreeNodeClick($event, item)"
                                    />
                                </div>
                                <label
                                    class="label"
                                    :for="item.id"
                                    >{{ item.name }}</label
                                >
                            </div>
                            <div
                                v-if="
                                    !item.checked &&
                                    !['student', 'teacher'].includes(item.type)
                                "
                                class="tree-next"
                                @click="treeNodeNextLevelClick(item)"
                            >
                                <img
                                    class="tree-next-img"
                                    :src="treeNextImg"
                                    alt="next"
                                />
                                <span class="tree-next-levels">下级</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="optional-load-title">
                {{ optionalLoadTitle }}
            </div>
        </div>
    </div>
</template>

<script>
import SelectTitle from "../../components/Title/SelectTitle.vue";
import SelectInput from "../../components/SelectInput/index.vue";
import SelectOption from "../../components/SelectOption/index.vue";
import Breadcrumbs from "../../components/Breadcrumbs/index.vue";

export default {
    name: "Optional",
    components: {
        SelectTitle,
        SelectInput,
        SelectOption,
        Breadcrumbs,
    },
    props: {
        meetingSelectPeopleList:Array,
        selectTreeNodeData:Array,
        currentType:Number,
        allCheck: Boolean,
        multiple: Boolean,
        dropDownSearchType: Boolean,
        treeShowList: Array,
        breadcrumbsList: Array,
        optionalLoadTitle: String,
        searchDropdownData: Array,
        searchOptionalLoadTitle: String,
        placeholder: String,
        checkUserOnly: {
            type: Boolean,
            default: () => {
                return false;
            },
        },
        checkAll: {
            type: Boolean,
            default: () => {
                return false;
            },
        }
    },
    computed: {
        treeNextImg() {
            return require("../../../img/next-levels.png");
        },
        hasOrgan() {
            return this.treeShowList.some(item => item.type === 'organ')
        }
    },
    data(){
        return {
            checkCitys:[],
        }
    },
    methods: {
        /**
         * @Description: 改变 添加参会人的选择种类
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 陈金伟
         * @Date: 2023-07-31 09:22:06
         */
        changeLaunchType(type){
            this.$emit('changeLaunchType',type)
        },
        /**
         * @Description: 按照人员选择
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 陈金伟
         * @Date: 2023-08-01 16:09:16
         * @param {*} val
         */
        meetingPeopleListChange(val){
            // 将选中的ID映射回对应的对象
            let arr=val.map(item=>{
                return this.meetingSelectPeopleList.filter(i=>{
                    return i.id==item
                })[0]
            })
            console.log(val,'val')
            // 选中的数据为空
            if(this.selectTreeNodeData.length!==0){
                // 查看选中的数据 有的 但是按人员选 没有的 (取消)
                let newIndex=this.selectTreeNodeData.findIndex((i)=>{
                    let index=val.findIndex(item=>{
                        return item==i.id
                    })
                    console.log(index,'666')
                    return index==-1&&!i.type
                })
                // 取消
                if(newIndex!==-1){
                    console.log(arr,"开始")
                    // arr.splice(newIndex,1)
                }
                console.log(newIndex,arr,'newIndex')
            }

            // 映射数据
            arr=arr.map(item=>{
                return {
                    ...item,
                    name:item.teacherName,
                    id:item.id,
                    schoolId:item.schoolId,
                }
            })
            // this.forestChecks=[...arr]//保存上一次的结果
            console.log("newArray0",arr)
            this.$emit('meetingPeopleListChange',arr)
        },
        selectFocus() {
            this.$emit("selectFocus");
        },
        selectInput(inputVal) {
            this.$emit("selectInput", inputVal);
        },
        handlerAllTreeNodeClick(ev) {
            this.$emit("handlerAllTreeNodeClick", {ev: ev});
        },
        handlerTreeNodeClick(e, item) {
            console.log(item,'选择的item')
            if (!this.multiple && item.type == 'origin') {
                return;
            }
            this.$emit("handlerTreeNodeClick", {e: e, item: item});
        },
        treeNodeNextLevelClick(item) {
            this.$emit("treeNodeNextLevelClick", {treeNode: item});
        },
        changeTreeListLevel(item) {
            this.$emit("changeTreeListLevel", {item: item});
        },
        handlerSelectTreeNodeClick (data) {
            const { e, item } = data;
            this.$emit("handlerTreeNodeClick", {e: e, item: item});
        }
    },
    watch:{
        // meetingSelectPeopleList:{
        //     handler(val) {
        //         console.log(val,'.')
        //         // this.checkCitys=this.meetingSelectPeopleList.filter((item=>{
        //         //     return item.checked==true
        //         // })).map(item=>{
        //         //     return item.id
        //         // })
        //     },
        //     deep: true,
        //     immediate:true,
        // },
        // 根据右侧选中的数据 同步  人员 和 架构(只能搞定添加)
        selectTreeNodeData:{
            handler(val){
                console.log(val,' selectTreeNodeData')
                // 先重置treeShowList
                for(let i=0;i<this.treeShowList.length;i++){
                    this.treeShowList[i].checked=false
                }
                // 先重置checkCitys
                this.checkCitys=[]
                this.selectTreeNodeData.map((item)=>{
                    // 查看每一项数据 是否能在架构中 选中
                    let index=this.treeShowList.findIndex((treeItem)=>{
                        return treeItem.id==item.id
                    })
                    // 找到了 判断是否是选中状态 没找到就是属于人员选中的
                    if(index!=-1){
                        this.treeShowList[index].checked=true
                    }
                    // 查看每一项数据 是否能在人员中 选中
                    let index1=this.meetingSelectPeopleList.findIndex((meetItem)=>{
                        return meetItem.id==item.id
                    })
                    // 找到了 判断是否是选中状态 没找到就是属于架构中选中的
                    if(index1!=-1){
                        // 查看当前人员是否是被选中的状态
                        let index3=this.checkCitys.findIndex((item2)=>{
                            return item2==this.meetingSelectPeopleList[index1].id
                        })
                        // 如果没找到 表示当前人员需要被添加进选中状态
                        if(index3==-1){
                            this.checkCitys.push(this.meetingSelectPeopleList[index1].id)
                        }
                    }
                })
            },
            // deep: true,
            immediate:true,
        }
    }
};
</script>
<style lang="scss" scoped>
/* tab选择标题 */
.meetingLaunchAddTab{
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 10px;
}
.commonSpan{
    cursor: pointer;
    color: black;
    font-size: 14px;
}
.activeType{
    color: #3C7FFF;
    position: relative;
}
.activeType::after{
    content: '';
    display: block;
    width: 50px;
    height: 3px;
    background-color: #3C7FFF;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 25px;
}
    .checkbox-people {
    display: flex;
    justify-content: space-between;
}

/* 用户头像 */
    .checkbox-people .checkbox-people-icon {
    width: 36px;
    height: 36px;
    background-color: #3C7FFF;
    border-radius: 4px;
    overflow: hidden;
}

    .checkbox-people .checkbox-people-icon img {
    width: 100%;
    height: 100%;
}

    .checkbox-people .checkbox-people-icon .checkbox-people-icon-text {
    height: 36px;
    color: #fff;
    font-size: 12px;
    text-align: center;
    line-height: 36px;
}

/* 用户姓名 */
    .checkbox-people .checkbox-people-name {
    min-width: 42px;
    height: 36px;
    color: #595959;
    font-size: 14px;
    line-height: 36px;
    margin-left: 10px;
}
    .el-checkbox {
    display: block;
    margin-top: 15px;
}
.box {
    width: 350px;
    box-sizing: border-box;
    padding: 0 0 0 24px;
}
.tree-wrapper {
    .tree-option {
        width: 100%;
        .all-check {
            display: flex;
            align-items: center;
            .all-check-label {
                line-height: 20px;
                display: inline-block;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #2b2f33;
                margin-left: 8px;
            }
        }
        .tree-list {
            width: 100%;
            height: 260px;
            overflow: auto;
            .tree-item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                box-sizing: border-box;
                padding: 8px 16px 8px 0px;
                .input-item {
                    display: flex;
                    align-items: center;
                    width: 220px;
                    .label {
                        margin-left: 8px;
                        font-size: 14px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #2b2f33;
                        cursor: pointer;
                    }
                }
                .tree-next {
                    width: 48px;
                    cursor: pointer;
                    .tree-next-img {
                        width: 10px;
                        height: 13px;
                    }
                    .tree-next-levels {
                        font-size: 14px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #3C7FFF;
                        margin-left: 6px;
                    }
                }
            }
        }
    }
    .optional-load-title {
        font-size: 14px;
        color: #2b2f33;
    }
}
.input {
    width: 18px;
    height: 18px;
}
.inputOrgan[type="checkbox"]::after{
    background-color: #dbdbdf;
}
input[type="checkbox"] {
    visibility: hidden;
    position: relative;
}
//针对火狐浏览器的样式
@media screen and (min--moz-device-pixel-ratio:0) {
	input[type="checkbox"] {
        visibility: visible;
        position: relative;
    }
}
input[type="checkbox"]:after {
    content: "";
    display: block;
    position: absolute;
    width: 18px;
    height: 18px;
    visibility: visible;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid #e1e3e6;
}
input[type="checkbox"]:checked::after {
    background: #3C7FFF;
}
input[type="checkbox"]:checked::before {
    content: "";
    display: block;
    position: absolute;
    left: 4px;
    top: 4px;
    width: 10px;
    height: 6px;
    border: 2px solid #fff;
    border-top-color: transparent;
    border-right-color: transparent;
    -ms-transform: rotate(-60deg);
    -moz-transform: rotate(-60deg);
    -webkit-transform: rotate(-60deg);
    transform: rotate(-45deg);
    visibility: visible;
    z-index: 1;
    box-sizing: border-box;
}
input[type="checkbox"] {
    content: "";
    display: block;
    background-color: #ffffff;
}
.disabled[type="checkbox"]:after {
    background-color: #dbdbdf;
}
</style>

<template>
    <div class="breadcrumbs">
        <div
            v-for="(item, index) of breadcrumbsList"
            :key="index"
            class="breadcrumbs-item"
            @click="changeTreeListLevel(item)"
        >
            <span class="breadcrumbs-item-wrapper">
                <span
                    class="partitions"
                    v-if="index != '0'"
                >
                    <img
                        :src="levels"
                        alt="levels"
                    />
                </span>
                <span :class="['breadcrumbs-item-title', { current: laseLevels(index) }]">{{ item.name }}</span>
            </span>
        </div>
    </div>
</template>
<script>
export default {
    name: "Breadcrumbs",
    props: {
        breadcrumbsList: {
            type: Array,
            default: () => {
                return [];
            },
        },
    },
    computed: {
        levels() {
            return require("../../../img/levels.png");
        }
    },
    methods: {
        laseLevels (index) {
            return this.breadcrumbsList.length - 1 == index;
        },
        changeTreeListLevel(item) {
            this.$emit("changeTreeListLevel", item);
        },
    },
};
</script>
<style lang="scss" scoped>
.breadcrumbs {
    width: 100%;
    height: 56px;
    line-height: 56px;
    box-sizing: border-box;
    padding: 0 20px 0 0;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    .breadcrumbs-item {
        display: inline-block;
    }
    .breadcrumbs-item-wrapper {
        .partitions {
            margin: 0 6px;
            display: inline-block;
            img {
                width: 8px;
            }
        }
        .breadcrumbs-item-title {
            color: #3C7FFF;
            font-size: 14px;
            cursor: pointer;
        }
        .breadcrumbs-item-title.current {
            color: #2B2F33;
        }
    }
}
</style>
